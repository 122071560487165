<template>
    <div>
        <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="PC端应用" name="pc">
                <Apply />
            </el-tab-pane>
            <el-tab-pane label="APP端应用" name="app">
                <MobileApplication />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Apply from '@/views/platform/apply/apply'
import MobileApplication from '@/views/platform/applyMobile/apply'
export default {
    /* 应用管理 */
    name: 'Application',

    components: { Apply, MobileApplication },

    data () {
        return {
            activeName: 'pc'
        }
    },

    methods: {
        handleClick () {}
    }
}
</script>

<style lang="scss" scoped>

</style>
