<template>
  <div id="apply">
      <TableWrap @callback="handlePagination" :total="total">
          <template slot="tablesearch">
            <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
          </template>
          <template slot="tableOperate">
              <el-button type="primary" size="small" @click="add">新增</el-button>
              <el-button type="primary" size="small" @click="amend">修改</el-button>
              <el-button type="danger" size="small" @click="del">删除</el-button>
          </template>
          <el-table :data="tableData" @selection-change="handleSelectionChange" v-loading="loading" :header-cell-style="{background:'#FAFAFA'}">
              <el-table-column type="selection" ></el-table-column>
              <el-table-column label="状态" prop="status" width="60"><template v-slot="scope">{{$utils.getDicValue("SYSTEM_NORMAL_STATUS",scope.row.status).mean}}</template></el-table-column>
              <el-table-column label="排序号" prop="orderNum"></el-table-column>
              <el-table-column label="应用名称" prop="name"></el-table-column>
              <el-table-column label="应用地址" prop="appUrl"></el-table-column>
              <el-table-column label="编码" prop="code"></el-table-column>
              <el-table-column label="创建时间" prop="createTime"></el-table-column>
              <el-table-column label="修改时间" prop="updateTime"></el-table-column>
              <el-table-column label="操作">
                  <template slot-scope="scope">
                      <el-button type="text" size="small" @click="skipToMenu(scope.row.id)">菜单管理</el-button>
                      <el-button type="text" size="small" @click="detailInf(scope.row)">详情</el-button>
                  </template>
              </el-table-column>
          </el-table>
      </TableWrap>
      <applyDrawer
        ref="apply"
        :operateTitle="operateTitle"
        :applyTable="tableData"
        :temp="temp"
        :detail="detail"
        :formData="formData"
        @callback="update"/>
  </div>
</template>

<script>
import applyDrawer from './applyDrawer'
import tableMixIn from '@/mixIn/tableMixIn'
import { reqMApply, reqMApplyDelById, reqMApplyInquireById } from '@/api/platform/apply'
export default {
    /* 应用 */
    name: 'apply',
    mixins: [tableMixIn],
    components: {
        applyDrawer,
    },
    data () {
        return {
            searchOptions: [{ type: 'input', key: 'name' }],
            reqTableData: reqMApply,
            // selectMuster: [], // 多选集合
            disabled: true, // 修改键禁用与否
            // 传值
            operateTitle: '', // 抽屉标题
            temp: 0, // 0-新增   1-修改
            detail: true, // 输入框禁用、抽屉中确认按钮隐藏、详情
            applyDrawer: {
                appIcon: '',
                appUr: '',
                code: '',
                createTime: '',
                creator: 0,
                extend: {},
                id: 0,
                name: 'string',
                note: 'string',
                orderNu: 0,
                status: '0',
                updateTime: '',
                updater: 0
            },
            // 新增、修改表单
            formData: {
                sortNum: '',
                appName: '',
                appAddress: '',
                powerCode: '',
                status: '0',
                catalogType: 2
            },
        }
    },
    created () {
        this.$utils.getDicData('SYSTEM_NORMAL_STATUS').then(res => {
            this.getTableData()
        })
    },
    methods: {
        skipToMenu (id) {
            this.$router.push({ name: 'applicationMenu', params: { id }, query: { moduleName: 'app' } })
        },

        update () {
            this.getTableData()
        },
        // 新增
        add () {
            this.operateTitle = 'add'
            this.temp = 0
            this.detail = true // 新增抽屉
            this.$refs.apply.drawerVisible = true
        },
        // 详情
        detailInf (row) {
            this.operateTitle = 'detail'
            this.detail = false // 详情抽屉
            reqMApplyInquireById({ id: row.id })
                .then(result => {
                    this.formData = result.data
                    this.formData.status = `${this.formData.status}`
                })
            this.$refs.apply.drawerVisible = true
        },
        // 修改
        amend () {
            const length = this.multipleSelection.length
            if (length === 1) {
                this.operateTitle = 'update'
                this.temp = 1
                this.detail = true // 修改抽屉

                reqMApplyInquireById({ id: this.multipleSelection[0].id })
                    .then(result => {
                        this.formData = result.data
                        this.formData.status = `${this.formData.status}`
                    })
                this.$refs.apply.drawerVisible = true
            } else if (length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选择您要修改的某行数据！'
                })
            } else {
                this.$message({
                    type: 'warning',
                    message: '只能修改一行数据！'
                })
            }
        },
        // 删除
        del () {
            if (this.multipleSelection.length === 1) {
                this.$confirm('是否确认删除', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const id = this.multipleSelection[0].id
                    reqMApplyDelById({ id: id })
                        .then(result => {
                            this.$message({
                                type: 'success',
                                message: '删除成功',
                            })
                            this.getTableData()
                        })
                        .catch(e => console.error(e))
                }).catch((e) => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
            } else {
                this.$message({
                    type: 'warning',
                    message: '请选择一条需要删除的数据！'
                })
            }
        },
    }

}
</script>

<style>

</style>
