<template>
    <div id="applyDrawer">
        <el-drawer :title="title" :visible.sync="drawerVisible"  :wrapperClosable="false" custom-class="cus-drawer">
            <!-- <div class="cus_drawer_container"> -->
                <div class="cus_drawer_content">
                <!-- 新增、修改 -->
                    <el-form
                        v-if="detail"
                        ref="appDrawer"
                        :model="formData"
                        :rules="rules"
                    >
                        <el-form-item label="排序号" prop="orderNum"
                            ><el-input
                                v-model="formData.orderNum" size="small"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="应用名称" prop="name"
                            ><el-input
                                v-model="formData.name" size="small"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="应用地址" prop="appUrl"
                            ><el-input
                                v-model="formData.appUrl" size="small"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="编码" prop="code"
                            ><el-input
                                v-model="formData.code" size="small"
                            ></el-input
                        ></el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-select v-model="formData.status" size="small" style="width:100%">
                                <el-option
                                    v-for="item in statusList"
                                    :key="item.val"
                                    :label="item.mean"
                                    :value="item.val"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <!-- 详情 -->
                    <el-form  v-else :model="formData" :rules="rules">
                        <el-form-item label="应用名称">
                             <el-input v-model="formData.name" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="应用地址" prop="appUrl">
                            <el-input v-model="formData.appUrl" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="权限编码" prop="code" >
                            <el-input v-model="formData.code" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="创建人" prop="creator">
                            <el-input v-model="formData.creator" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="创建时间" prop="createTime">
                            <el-input v-model="formData.createTime" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="修改人" prop="updater">
                            <el-input v-model="formData.updater" size="small" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="修改时间" prop="updateTime">
                            <el-input v-model="formData.updateTime" size="small" disabled></el-input>
                        </el-form-item>
                    </el-form>
                <!-- </div> -->
                <footer class="cus_drawer_footer">
                    <el-button size="small" plain @click="close()">取消</el-button>
                    <el-button type="primary" size="small" @click="submit" v-if="detail"
                        >确认</el-button
                    >
                </footer>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import tableMixin from '@/mixIn/tableMixIn'
import { reqMApplyAdd, reqMApplyUpdate } from '@/api/platform/apply'
export default {
    /* 应用管理弹框 */
    name: 'applyDrawer',

    mixins: [tableMixin],

    props: {
        operateTitle: {
            type: String,
            default: ''
        },
        applyTable: { type: Array },
        temp: { // 新增0、修改1
            type: Number,
            default: 0
        },
        detail: { // 输入框禁用、抽屉中确认按钮隐藏、详情
            type: Boolean,
            default: true
        },
        formData: { type: Object }
    },

    data () {
        return {
            drawerVisible: false,
            title: '',
            statusList: [],
            rules: {
                name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
                code: [{ required: true, message: '请输入权限编码', trigger: 'blur' }],
            }
        }
    },
    created () {
        this.$utils.getDicData('SYSTEM_NORMAL_STATUS')
            .then((result) => {
                this.statusList = result
            })
    },
    watch: {
        operateTitle (v) {
            if (v === 'add') {
                this.title = '新增'
            } else if (v === 'detail') {
                this.title = '详情'
            } else if (v === 'update') {
                this.title = '修改'
            }
        },
    },

    methods: {
        // 关闭清除
        close () {
            const { detail } = this
            for (const i in this.formData) {
                this.formData[i] = ''
            }
            if (detail) {
                this.$refs.appDrawer.clearValidate()
            }
            this.drawerVisible = false
            // console.log(this.formData)
        },

        // 提交新增/修改
        submit () {
            this.$refs.appDrawer.validate(valid => {
                if (valid) {
                    // 新增
                    if (this.temp === 0) {
                        // const newForm = {}
                        this.formData.createTime = dayjs(Date()).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                        reqMApplyAdd(this.formData)
                            .then((result) => {
                                this.$emit('callback')
                            })
                    } else {
                        // 修改
                        this.formData.updateTime = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')

                        reqMApplyUpdate(this.formData)
                            .then(result => { this.$emit('callback') })
                    }
                    this.drawerVisible = false
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style></style>
